import { useRef, useState } from "react";
import { Form, Row, Col, Button, Overlay, Tooltip } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import useMarkedFields from "../../Hooks/validationHook";
import style from "./../../styles/ResetPassword.module.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { toast } from "react-toastify";

import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
const AddUser = ({ id, data, close, toggle }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [userType, setUserTyper] = useState(null);
  const [shopKepper, setShopKepper] = useState(false);
  const [userPage, setUserPage] = useState(false);
  const [pointSystem, setPointSystem] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [bindField, setAllFieldsTouched] = useMarkedFields();
  const addTechuser = useApi(postapi.AddtechUser, { hasCatchError: true });
  const addAdminuser = useApi(postapi.AddAdminUser, { hasCatchError: true });
  const editAdminn = useApi(postapi.editAdmin, { hasCatchError: true });
  const editUser = useApi(postapi.editTechUser, { hasCatchError: true });
  const passwordTarget = useRef(null);
  const [showTooltipOverPassword, setShowTooltipOverPassword] = useState(false);
  const [forcedRedirection, setForcedRedirection] = useState(false);
  const [registrationRequests, setRegistrationRequests] = useState(false);
  const [dataImport, setDataImport] = useState(false);
  const [payment, setPayment] = useState(false);
  const [component, setComponent] = useState(false);
  const [bank, setBank] = useState(false);
  const [themes, setThemes] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [addonsRequests, setAddonsRequests] = useState(false);
  const [pricing, setPricing] = useState(false);
  const [deliveryOrder, setDeliveryOrder] = useState(false);
  const [marketplaceOptions, setMarketplaceOptions] = useState(false);
  const [userDeletionRequests, setUserDeletionRequests] = useState(false);
  useEffect(() => {
    if (data) {
      setName(data?.user.user_full_name);
      setEmail(data?.user.user_email);
      setUserTyper({
        value: data?.user.user_type,
        label: data?.user.user_type,
      });
      setShopKepper(data?.permissions?.Shopkeeper === 1 ? true : false);
      setUserPage(data?.permissions?.User === 1 ? true : false);
      setSubscription(data?.permissions?.Subscription === 1 ? true : false);
      setPointSystem(data?.permissions?.PointSystem === 1 ? true : false);
      setForcedRedirection(data?.permissions?.ForcedRedirections === 1 ? true : false);
      setRegistrationRequests(data?.permissions?.RegistrationRequests === 1 ? true : false);
      setDataImport(data?.permissions?.DataImport === 1 ? true : false);
      setPayment(data?.permissions?.Payment === 1 ? true : false);
      setComponent(data?.permissions?.Component === 1 ? true : false);
      setBank(data?.permissions?.Bank === 1 ? true : false);
      setThemes(data?.permissions?.Themes === 1 ? true : false);
      setNotifications(data?.permissions?.Notifications === 1 ? true : false);
      setAddonsRequests(data?.permissions?.AddonsRequests === 1 ? true : false);
      setDeliveryOrder(data?.permissions?.DeliveryOrder === 1 ? true : false);
      setMarketplaceOptions(data?.permissions?.MarketplaceOptions === 1 ? true : false);
      setPricing(data?.permissions?.Pricing === 1 ? true : false);
      setUserDeletionRequests(data?.permissions?.UserDeletionRequests === 1 ? true : false);
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data) {
      try {
        const user = {
          user_full_name: name,
          user_email: email,
        };
        if (data.user.user_type === "admin" || data.user.user_type === "super-admin") {
          const permissions = {
            Shopkeeper: userType.value === "super-admin" ? true : shopKepper,
            User: userType.value === "super-admin" ? true : userPage,
            Subscription: userType.value === "super-admin" ? true : subscription,
            PointSystem: userType.value === "super-admin" ? true : pointSystem,
            ForcedRedirections: userType.value === "super-admin" ? true : forcedRedirection,
            RegistrationRequests: userType.value === "super-admin" ? true : registrationRequests,
            DataImport: userType.value === "super-admin" ? true : dataImport,
            Payment: userType.value === "super-admin" ? true : payment,
            Component: userType.value === "super-admin" ? true : component,
            Bank: userType.value === "super-admin" ? true : bank,
            Themes: userType.value === "super-admin" ? true : themes,
            Notifications: userType.value === "super-admin" ? true : notifications,
            AddonsRequests: userType.value === "super-admin" ? true : addonsRequests,
            DeliveryOrder: userType.value === "super-admin" ? true : deliveryOrder,
            MarketplaceOptions: userType.value === "super-admin" ? true : marketplaceOptions,
            Pricing: userType.value === "super-admin" ? true : pricing,
            UserDeletionRequests: userType.value === "super-admin" ? true : userDeletionRequests,
          };
          await editAdminn.request({ id, user, permissions });
        } else {
          await editUser.request(id, user);
        }
        close();
        toggle((prev) => !prev);

        toast.success("user Edited Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {}
    } else {
      try {
        setError("");
        let data;
        const user = {
          user_full_name: name,
          user_email: email,
          password: password,
          user_type: userType?.value,
        };
        if (userType.value === "admin" || userType.value === "super-admin") {
          const permissions = {
            Shopkeeper: userType.value === "super-admin" ? true : shopKepper,
            User: userType.value === "super-admin" ? true : userPage,
            Subscription: userType.value === "super-admin" ? true : subscription,
            PointSystem: userType.value === "super-admin" ? true : pointSystem,
            ForcedRedirections: userType.value === "super-admin" ? true : forcedRedirection,
            RegistrationRequests: userType.value === "super-admin" ? true : registrationRequests,
            DataImport: userType.value === "super-admin" ? true : dataImport,
            Payment: userType.value === "super-admin" ? true : payment,
            Component: userType.value === "super-admin" ? true : component,
            Bank: userType.value === "super-admin" ? true : bank,
            Notifications: userType.value === "super-admin" ? true : notifications,
            Themes: userType.value === "super-admin" ? true : themes,
            AddonsRequests: userType.value === "super-admin" ? true : addonsRequests,
            DeliveryOrder: userType.value === "super-admin" ? true : deliveryOrder,
            MarketplaceOptions: userType.value === "super-admin" ? true : marketplaceOptions,
            Pricing: userType.value === "super-admin" ? true : pricing,
            UserDeletionRequests: userType.value === "super-admin" ? true : userDeletionRequests,
          };
          data = await addAdminuser.request({ user, permissions });
        } else {
          data = await addTechuser.request(user);
        }
        if (data?.data?.success) {
          success();
        }
        function success() {
          toggle((prev) => !prev);
          close();
          toast.success("user Added Successfully", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        setError(error.response?.data.error.message);
      }
    }
  };
  return (
    <>
      <Form autoComplete="nope" onSubmit={handleSubmit}>
        {error && <p className="text-danger p-2 text-center my-3 rounded-1">*{error}</p>}
        {success && <p className="text-success p-2 text-center my-3 rounded-1">*{success}</p>}
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Full Name <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control className="p-2" type="text" name="NaMe" {...bindField("NaMe")} placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Email <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control autoComplete="nope" className="p-2" type="text" name="mail" {...bindField("mail")} placeholder="example@mail.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </Form.Group>
        {!data && (
          <Form.Group className={`mb-4 ${style.passwordInput}`}>
            <Form.Label>Password {data ? "" : <span style={{ color: "red" }}>*</span>}</Form.Label>
            <Overlay target={passwordTarget.current} show={showTooltipOverPassword} placement="right">
              {(props) => (
                <Tooltip style={{ background: "#ffecb5" }} id="overlay-example" {...props}>
                  Password must be greater than 5 characters
                </Tooltip>
              )}
            </Overlay>
            <Form.Control
              ref={passwordTarget}
              autoComplete="nope"
              className="p-2"
              name="pass"
              {...bindField("pass")}
              type={showPassword ? "text" : "password"}
              placeholder="******"
              required={data ? false : true}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (e.target.value.length < 6) {
                  setShowTooltipOverPassword(true);
                  setTimeout(() => setShowTooltipOverPassword(false), 2000);
                }
              }}
            />
            <div className={`${style.passwordField}`}>
              <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
            </div>
            <span className="invalid-text" type="invalid">
              * Please enter password
            </span>
          </Form.Group>
        )}
        {!data && (
          <div className="mb-3">
            <SearchableSelect
              important
              name="user-type"
              autoComplete="nope"
              searchable={false}
              value={userType}
              label={"User Type"}
              placeholder="Select User Type "
              options={[
                { value: "marketing", label: "Marketing" },
                { value: "seo", label: "SEO" },
                { value: "data-analyst", label: "Data Analyst" },
                { value: "data-operator", label: "Data Operator" },
                { value: "super-admin", label: "Super Admin" },
                { value: "admin", label: "Admin" },
              ]}
              handleChange={(e) => setUserTyper(e)}
              required
            />
          </div>
        )}

        {userType?.value === "admin" && (
          <Row className="py-2 mb-2">
            <Form.Label className="mb-1">
              Permissions <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Shopkeeper" checked={shopKepper} onChange={(e) => setShopKepper(e.target.checked)} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="User"
                  checked={userPage}
                  onChange={(e) => {
                    setUserPage(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Subscription" checked={subscription} onChange={(e) => setSubscription(e.target.checked)} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Point System" checked={pointSystem} onChange={(e) => setPointSystem(e.target.checked)} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Data Import"
                  checked={dataImport}
                  onChange={(e) => {
                    setDataImport(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Payment"
                  checked={payment}
                  onChange={(e) => {
                    setPayment(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Component"
                  checked={component}
                  onChange={(e) => {
                    setComponent(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Bank"
                  checked={bank}
                  onChange={(e) => {
                    setBank(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Forced Redirection"
                  checked={forcedRedirection}
                  onChange={(e) => {
                    setForcedRedirection(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Seller Registration"
                  checked={registrationRequests}
                  onChange={(e) => {
                    setRegistrationRequests(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Themes"
                  checked={themes}
                  onChange={(e) => {
                    setThemes(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="SMS/Notifications"
                  checked={notifications}
                  onChange={(e) => {
                    setNotifications(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Addons Requests"
                  checked={addonsRequests}
                  onChange={(e) => {
                    setAddonsRequests(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Pricing"
                  checked={pricing}
                  onChange={(e) => {
                    setPricing(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="User Deletion Requests"
                  checked={userDeletionRequests}
                  onChange={(e) => {
                    setUserDeletionRequests(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Marketplace Options"
                  checked={marketplaceOptions}
                  onChange={(e) => {
                    setMarketplaceOptions(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Delivery Orders"
                  checked={deliveryOrder}
                  onChange={(e) => {
                    setDeliveryOrder(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Button
          type="submit"
          className="w-100"
          onClick={() => {
            setAllFieldsTouched();
          }}
        >
          Save
          {/* {data ? "Edit" : "Save"} */}
        </Button>
      </Form>
    </>
  );
};

export default AddUser;
